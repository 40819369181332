<template>
    <div class="float-box" :class="fixed?'fixed':''" :style="{left:left,top:top}"
    @dragstart="dragstart"
            @drag="drag">
        <el-input v-model="value" :placeholder="placeholder">
            <el-button slot="append" 
            icon="el-icon-search" @click="search"
            ></el-button>
        </el-input>
    </div>
</template>
<script>
export default {
    props:{
        placeholder:{
            type:String,
            value:'请输入搜索内容'
        },
        fixed:{
            type:Boolean,
            value:false
        },
        top:{
            type:String,
            value:'50px'
        },
        left:{
            type:String,
            value:'150px'
        }
    },
    data(){
        return {
            value:''
        }
    },
    methods:{
        search(){
            this.$emit("search",this.value)
        },
        drag(e){
            console.log(e)
        },
        dragstart(e){
            console.log(e)
        },
        dragend(e){
            console.log(e)
        }
    }
}
</script>
<style lang="less">
.fixed{
    position:absolute;
}
.float-box{
    top:20px;
    left:100px;
    width:320px;
    display: flex;
    height: 50px;
    z-index: 10;
}
</style>