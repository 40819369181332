<template>
    <div class="ca-list" @click="itemClick(id)" :id="id">
        <div class="top-menu" v-if="showTop">
            <!-- 显示状态 -->
            <el-switch
            v-model="statusText"
            active-text="显示状态">
            </el-switch>
            <el-switch
            v-model="staffText"
            active-text="显示员工">
            </el-switch>
        </div>
        <template v-for="(item,index) in list">
            <div class="item" :key="index">
            <!-- <i class="el-icon-caret-right"  :class="'status-'+item.status"/> -->
               <!-- <span :class="'status-'+item.status"> </span>  -->
               <el-tag type="danger" v-if="item.selected" effect="dark">
                   {{item.client_name}}
               </el-tag>
               <el-tag :type="item.status|matchStatus" v-else
                :effect="effect"
                :title="item.status|statusFormat">
                   {{item.client_name}}
               </el-tag>
               <span v-if="statusText"  :class="'status status-'+item.status">
                   {{item.status|statusFormat}}
               </span>
               <span class="staff_name" v-if="staffText">
                   {{item.staff_name}}
               </span>
               <!-- <font :title="item.status|statusFormat">
                   {{item.client_name}}
               </font> -->
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props:{
        showTop:{
            type:Boolean,
            default:false
        },
        effect:{
            type:String,
            value:'dark'
        },
        date:{
            type:String,
            value:''
        },
        list:{
            type:Array,
            value:[]
        },
        id:{
            type:Number,
            value:''
        }
    },
    data(){
        return{
            staffText:false,
            statusText:false
        }
    },
    methods:{
        itemClick(e){
            //console.log(e)
            this.$emit("itemClick",e)
        }
    }
}
</script>
<style lang="less">
.ca-list{
    z-index: 1;
    width:100%;
    height:100%;
    color: #333;
    position: relative;
    overflow-y: scroll;
    .top-menu{
        display: flex;
        padding: 5px 8px;
        border-bottom: 1px solid #F8F8F8;
        .el-switch__core{
            margin-left: 10px;
        }
    }
    .item{
        padding:5px;
        font-size: small;
        font-weight: 300;
        overflow: hidden;
        vertical-align: middle;
        line-height:30px;
        display: flex;
        text-overflow:ellipsis; white-space: nowrap;
        span{
            padding:2px;
            margin:5px;
            // width:5px;
            // height:20px;
            // margin:5px;
            // padding: 5px 1px;
        }
        span.status{
            font-size: small;
        }
        .status-0{
            color:rgb(51, 192, 150)
        }
        .status-1{
            color:rgb(102, 106, 110)
        }
        .status-2{
            color:#39F
        }
        .status-3{
            color:#39F
        }
        .status-4{
            color:rgb(158, 4, 4)
        }
        .status-5{
            color:rgb(7, 153, 19)
        }
        .staff_name{
            flex: 1;
            text-align: right;
        }
    }
}
</style>