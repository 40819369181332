<template>
    <div class="cal-box">
        <el-calendar v-model="value" v-loading="loading">
          <!-- 自定内容 -->
             <template
              slot="dateCell"
              slot-scope="{data}">
                <template v-for="(item,index) in dataList" style="z-index:1;" :id="index">
                    <div class="show-num" v-if="showNumber&&data.day==item.date" v-bind:key="index">
                      {{item.res.length}}
                    </div>
                    <CalendarItem :key="item.date" :date="item.date" :list="item.res" :id="index" @itemClick="itemClick"
                    v-if="data.day==item.date"/>
                </template>
                
                <div class="month-item">{{data.day|monthFormat}}</div>
            </template>
        </el-calendar>
        <!-- 显示任务 -->

        <el-drawer
          :title="currentData.date"
          :visible.sync="drawer"
          :direction="direction">
          <CalendarItem :showTop="true" :list="currentData.res" />
        </el-drawer>

      <!-- 搜索框 -->
      <Search fixed :top="searchTop" placeholder="输入客户名搜索"
      :left="searchLeft" @search="search"/>
    </div>
</template>
<script>
import {getMonthDay,regroupByItem} from '@/utils/util.js'
import CalendarItem from '@/components/common/Calendar-item'
import Search from '@/components/common/Search'
  export default {
    components:{
      CalendarItem,
      Search
    },
    data() {
      return {
        searchTop:'80px',
        searchLeft:(document.documentElement.clientWidth/2-160)+'px',
        showNumber:true,
        currentData:{},//当前日期的任务
        drawer: false,
        direction: 'rtl',
        loading:false,
        value: new Date(),
        dataList:[],
        pageSize:20000,//最多加载的条数
        page:1,
      }
    },
    created(){
      this.getTaskList()
    },
    mounted(){
            this.$nextTick(() => {
                // 点击前一个月
                let prevBtn = document.querySelector(
                    '.el-calendar__button-group .el-button-group>button:nth-child(1)');
                prevBtn.addEventListener('click', () => {
                    console.log(this.value);
                    this.getTaskList()
                })
            })
            this.$nextTick(() => {
                // 点击后一个月
                let prevBtn = document.querySelector(
                    '.el-calendar__button-group .el-button-group>button:last-child');
                prevBtn.addEventListener('click', () => {
                    console.log(this.value);
                    this.getTaskList()
                })
            })
    },
    methods:{
      //搜索
      search(e){
        let list = this.dataList,number=0,dateArray=[]
        //console.log(list)
        list.forEach(item=>{
          let selected=false
          item.res.map(val=>{
            if(e!=''&&val.client_name.indexOf(e)!=-1){
              number++
              val.index=0
              val.selected=true
              selected=true
            }else{
              val.index=1
              val.selected=false
            }
          })
          //排序
          item.res.sort(this.sortIndex)
          if(selected){
            //当天有记录的
            dateArray.push(item.date)
          }
        })
        this.$message({
          type:'success',
          message:'共找到'+number+'条记录，分别位于'+dateArray.length+'天中'
        });
        //console.log(this.dataList,number)
      },
      //排序1,2,3..
      sortIndex(a,b){  
       return a.index-b.index  
      },
      itemClick(e){
        this.drawer=true 
        this.currentData = this.dataList[e]
      },
      getTaskList(){
        let days = getMonthDay(this.value.getMonth(),this.value.getFullYear())
        let start = this.value.getFullYear()+'-'+(this.value.getMonth()+1)+"-01 00:00:00"
        let end = this.value.getFullYear()+'-'+(this.value.getMonth()+1)+'-'+days+' 00:00:00'
        let params = {
                method:'getTask',
                pageSize:this.pageSize,
                page:this.page,
                start:start,
                end: end
        }
        //console.log(params)
        this.loading = true
        this.$ajax.post(this.API.api,params).then(res=>{
          //todo
          console.log(res.data)
          let data = res.data.list
          data.forEach(function(item){
            item.date = item.start_time.split(' ')[0]
            item.selected = false
          })
          //console.log(data) 
          this.dataList= regroupByItem(data,'day')
          this.loading = false 
          //console.log(list)
        }).catch(err=>{
          //err
          console.log(err)
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="less">
.el-calendar-table .el-calendar-day{
  height:120px;
}
.selected{
  background: fuchsia;
}
td{
  overflow-y: scroll;
  height: auto;
  .show-num{
    color: #39F;
    opacity: 0.6;
  }
  .el-calendar-day{
    position: relative;
    .month-item{
          top:0;
          color: #999;
          opacity: 0.2;
          position: absolute;
          z-index: 0;
          font-weight: bold;
          font-size:2.6em;
          top:50%; left:50%;
          margin-top:-20px;
          margin-left:-20px;
    }
  }
    
}
.el-calendar-table{
      width: 100%;
      height: 100%;
      &:not(.is-range){
        //使不是本月的日期不可点击，不会跳转到其他月份
        td.next{
        pointer-events: none;
      }
      td.prev{
        pointer-events: none;
     }
  }
}


</style>